export const Home = {
  model_list: [],

  mileages: [
    { label: "20 000 km", value: "20 000" },
    { label: "35 000 km", value: "35 000" },
    { label: "50 000 km", value: "50 000" },
    { label: "75 000 km", value: "75 000" },
    { label: "100 000 km", value: "100 000" },
    { label: "125 000 km", value: "125 000" },
    { label: "150 000 km", value: "150 000" },
    { label: "200 000 km", value: "200 000" },
    { label: "250 000 km", value: "250 000" },
  ],

  initialize: function() {
    if ($('#home').length) {
      Home.bindSlick()
      Home.bindSlider()
      Home.bindForm()
      Home.bindMaps()
    }
  },

  bindSlick: function() {
    $('.slick-list.home-header').slick({
      dots: true,
    })
    $('#media-about-us').slick({
      dots: false,
      arrows: true,
      arrow: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            draggable: false,
            swipe: false,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: false,
            swipe: false,
          }
        }
      ]
    })
    $('#opinions').slick({
      dots: false,
      arrows: true,
      arrow: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            draggable: false,
            swipe: false,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: false,
            swipe: false,
          }
        }
      ]
    })

    $('.slick-list.home-cars').on('init', function(event, slick){
      bindImageHover();
    });
    $('.slick-list.home-cars').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      bindImageHover();
    });
    $('.slick-list.home-cars').slick({
      lazyLoad: 'ondemand',
      dots: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            draggable: false,
            swipe: false,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: false,
            swipe: false,
          }
        }
      ]
    })
  },

  setModelList: function() {
    var model_selectize = $("#model-select")[0].selectize
    var list = Home.model_list[$("#brand-select")[0].selectize.getItem($("#brand-select").val()).data("brandId")]
    model_selectize.setValue("", false)
    model_selectize.clearOptions()
    if (list) {
      $.each(list, function(index, opt) {
        model_selectize.addOption(opt)
      })
      model_selectize.enable(); 
      $(".filter-item.model").removeClass("disabled")
    } else {
      model_selectize.disable();
      $(".filter-item.model").addClass("disabled")
    }
  },

  bindForm: function() {
    Home.model_list = $("#model-select").data("modelList");

    $("#brand-select").selectize({
      labelField: 'name',
      searchField: 'name',
      valueField: 'name',
      options: $("#brand-select").data("options"),
      render: {
        item: function (data, escape) {
          return "<div class='item' data-brand-id='"+data.brand_id+"' data-default-value='"+data.value+"'>"+escape(data.name)+"</div>"
        }
      }
    }).change(function() {
      Home.setModelList();
      Home.setOfferCount()
    })

    $("#model-select").selectize({
      sortField: "value",
      searchField: 'value',
    }).change(function() {
      Home.setOfferCount()
    })

    $('.tab-controler__button').click(function() {
      $('.tab-controler__button').removeClass('--active')
      $(this).addClass('--active')
      $('.tab-container__tab').hide()
      $('.tab-container__tab[data-tab-type='+$(this).attr('data-type-button')+']').show()
    })
    var form = $("form.inch-slider-search")
    form.find("select").change(function() {
      Home.setOfferCount()
    });
    form.find(".inch-search__button--more").click(function() {
      $(this).parent().addClass("loading")
      var input = $("<input>").attr("type", "hidden").attr("name", "openfilters").val("true");
      form.append(input);
      form.submit();
    })
    form.find(".inch-search__button--filter").click(function() {
      $(this).parent().addClass("loading")
    })

    form.submit(function(event) {
      event.preventDefault();
      if ($("#brand-select").val().match(/motocykle/)) {
        form.attr("action", "/motocykle")
      } else {
        form.attr("action", "/samochody")
      }
      if ($("#brand-select").val().length) {
        form.append("<input type='hidden' name='brand' value='"+$("#brand-select")[0].selectize.getItem($("#brand-select").val()).data("defaultValue")+"'/>")
      }
      event.currentTarget.submit();
    })
  },

  setOfferCount: function() {
    var form = $("form.inch-slider-search")
    var params = form.serializeArray()
    params.push({
      name: "count",
      value: "true"
    })
    if ($("#brand-select").val().match(/motocykle/)) {
      var url = "/motocykle"
      params.push({
        name: "brand",
        value: $("#brand-select")[0].selectize.getItem($("#brand-select").val()).data("defaultValue")
      })
    } else {
      var url = "/samochody"
    }
    $.get(url, params, function(data) {
      $(".offer_count span").text(data.total)
    })
  },

  setModelSelect: function() {
    $('select[name=model] option').attr('disabled', true)
    $('select[name=model]').attr('disabled', true)
    if ($("select[name='brand']").val()) {
      $('select[name=model] option[data-brand-id="'+$('select[name=brand] option:selected').data("id")+'"]').attr('disabled', false)
      $('select[name=model]').attr("disabled", false)
    }
  },

  bindSlider: function() {
    $.each($(".slider-home"), function(i, slider) {
      $(slider).slider({
        range: true,
        min: $(slider).data("min"),
        max: $(slider).data("max"),
        values: [ $(slider).data("min"),
                  $(slider).data("max") ],
        slide: function( event, ui ) {
          var box = $(ui.handle).parents('ul.inch-filters__items')
          box.find('.min input').val(ui.values[0])
          box.find('.min .inch-filter__fake-input').text(ui.values[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))
          box.find('.max input').val(ui.values[1])
          box.find('.max .inch-filter__fake-input').text(ui.values[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))
        },
        stop: function( event, ui ) {
          Home.setOfferCount()
        }
      });
    });
    $('.price .max input, .price .min input').change(function(){
      var box = $(this).parents('ul.inch-filters__items')
      box.find('.min .inch-filter__fake-input').text(box.find('.min input').val().replace(/\B(?=(\d{3})+(?!\d))/g, " "))
      box.find('.max .inch-filter__fake-input').text(box.find('.max input').val().replace(/\B(?=(\d{3})+(?!\d))/g, " "))
      box.find('.slider-home').slider("values", [
        box.find('.min input').val(),
        box.find('.max input').val()
      ])
    })
  },

  bindMaps: function() {
            const latPosition = 52.0643575
            const lngPosition = 19.913748
            const poz = new google.maps.LatLng(52.437766, 16.761222);
            const waw = new google.maps.LatLng(52.2363088, 20.9565245);
            const wro = new google.maps.LatLng(51.06152436386761, 16.995127251368018);
            const pol = new google.maps.LatLng(52.0643575, 19.913748);
            const mapOptions = {
              zoom: 6,
              center: pol,
              styles: [
                {
                  featureType: 'all',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#f5f5f5'
                    }
                  ]
                },
                {
                  featureType: 'all',
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#616161'
                    }
                  ]
                },
                {
                  featureType: 'all',
                  elementType: 'labels.text.stroke',
                  stylers: [
                    {
                      color: '#f5f5f5'
                    }
                  ]
                },
                {
                  featureType: 'all',
                  elementType: 'labels.icon',
                  stylers: [
                    {
                      visibility: 'off'
                    }
                  ]
                },
                {
                  featureType: 'administrative',
                  elementType: 'geometry',
                  stylers: [
                    {
                      visibility: 'on'
                    }
                  ]
                },
                {
                  featureType: 'administrative.country',
                  elementType: 'geometry.stroke',
                  stylers: [
                    {
                      visibility: 'on'
                    },
                    {
                      weight: '3.5'
                    }
                  ]
                },
                {
                  featureType: 'administrative.neighborhood',
                  elementType: 'geometry.fill',
                  stylers: [
                    {
                      color: '#fffbfc'
                    }
                  ]
                },
                {
                  featureType: 'administrative.land_parcel',
                  elementType: 'geometry.fill',
                  stylers: [
                    {
                      color: '#fffbfc'
                    }
                  ]
                },
                {
                  featureType: 'landscape.man_made',
                  elementType: 'geometry.fill',
                  stylers: [
                    {
                      color: '#e4e3df'
                    }
                  ]
                },
                {
                  featureType: 'landscape.natural',
                  elementType: 'geometry.fill',
                  stylers: [
                    {
                      visibility: 'off'
                    }
                  ]
                },
                {
                  featureType: 'poi',
                  elementType: 'all',
                  stylers: [
                    {
                      visibility: 'off'
                    }
                  ]
                },
                {
                  featureType: 'road',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#ffffff'
                    }
                  ]
                },
                {
                  featureType: 'road.highway',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#aeaeae'
                    }
                  ]
                },
                {
                  featureType: 'road.highway',
                  elementType: 'geometry.stroke',
                  stylers: [
                    {
                      weight: '0.25'
                    }
                  ]
                },
                {
                  featureType: 'road.highway.controlled_access',
                  elementType: 'geometry',
                  stylers: [
                    {
                      visibility: 'on'
                    },
                    {
                      weight: '0.50'
                    },
                    {
                      color: '#989898'
                    }
                  ]
                },
                {
                  featureType: 'water',
                  elementType: 'geometry.fill',
                  stylers: [
                    {
                      color: '#bfbfbf'
                    }
                  ]
                }
              ]

            };

            const mapElement = new google.maps.Map(document.getElementById('inch-maps__container'), mapOptions);
            const wawMarker = new google.maps.Marker({
              map: mapElement,
              position: waw,
            });
            const wroMarker = new google.maps.Marker({
              map: mapElement,
              position: wro,
            });
            const pozMarker = new google.maps.Marker({
              map: mapElement,
              position: poz,
            });

            const blueCoordinatesWarszawa = [
              { lat: 52.235942, lng: 20.956426 },
              { lat: 52.235949, lng: 20.956326 },
              { lat: 52.235951, lng: 20.956283 },
              { lat: 52.235936, lng: 20.956257 },
              { lat: 52.235895, lng: 20.956254 },
              { lat: 52.235385, lng: 20.956214 },
              { lat: 52.234221, lng: 20.956129 },
              { lat: 52.233843, lng: 20.95614 },
              { lat: 52.232949, lng: 20.956317 },
              { lat: 52.232668, lng: 20.956403 },
              { lat: 52.232405, lng: 20.956371 },
              { lat: 52.231308, lng: 20.956693 },
              { lat: 52.230527, lng: 20.956824 },
              { lat: 52.229915, lng: 20.956961 },
              { lat: 52.228029, lng: 20.957401 },
              { lat: 52.227805, lng: 20.957433 },
              { lat: 52.227739, lng: 20.957358 },
              { lat: 52.227713, lng: 20.957197 },
              { lat: 52.227707, lng: 20.957057 },
              { lat: 52.227799, lng: 20.956907 },
              { lat: 52.230147, lng: 20.956379 },
              { lat: 52.23104, lng: 20.956103 },
              { lat: 52.232155, lng: 20.955876 },
              { lat: 52.233213, lng: 20.955715 },
              { lat: 52.233368, lng: 20.955689 },
              { lat: 52.233539, lng: 20.955742 },
              { lat: 52.235169, lng: 20.955687 },
              { lat: 52.235786, lng: 20.955698 },
              { lat: 52.236397, lng: 20.955805 },
              { lat: 52.237356, lng: 20.955859 },
              { lat: 52.237941, lng: 20.955966 },
              { lat: 52.239538, lng: 20.956556 },
              { lat: 52.241723, lng: 20.95737 }
            ];
            const redCoordinatesWarszawa = [
              { lat: 52.228754, lng: 20.967983 },
              { lat: 52.22861, lng: 20.966332 },
              { lat: 52.228406, lng: 20.963334 },
              { lat: 52.228298, lng: 20.961645 },
              { lat: 52.228243, lng: 20.960083 },
              { lat: 52.228219, lng: 20.95845 },
              { lat: 52.22816, lng: 20.957522 },
              { lat: 52.228169, lng: 20.957297 },
              { lat: 52.228173, lng: 20.957249 },
              { lat: 52.228242, lng: 20.957163 },
              { lat: 52.22908, lng: 20.956981 },
              { lat: 52.23069, lng: 20.956615 },
              { lat: 52.23187, lng: 20.95632 },
              { lat: 52.232945, lng: 20.956036 },
              { lat: 52.233921, lng: 20.955955 },
              { lat: 52.235419, lng: 20.955977 },
              { lat: 52.235718, lng: 20.955998 },
              { lat: 52.236559, lng: 20.956089 },
              { lat: 52.236608, lng: 20.956095 },
              { lat: 52.236661, lng: 20.956148 },
              { lat: 52.236664, lng: 20.956197 },
              { lat: 52.236661, lng: 20.956261 },
              { lat: 52.236654, lng: 20.956315 },
              { lat: 52.236611, lng: 20.95632 },
              { lat: 52.23601, lng: 20.956272 },
              { lat: 52.235981, lng: 20.956288 },
              { lat: 52.235961, lng: 20.956433 }
            ];
            const blueCoordinatesPoznan = [
              { lat: 52.43779, lng: 16.761023 },
              { lat: 52.438738, lng: 16.753621 },
              { lat: 52.439424, lng: 16.754033 },
              { lat: 52.439467, lng: 16.753698 },
              { lat: 52.439559, lng: 16.753428 },
              { lat: 52.43967, lng: 16.753222 },
              { lat: 52.439701, lng: 16.753179 },
              { lat: 52.43985, lng: 16.753065 },
              { lat: 52.439909, lng: 16.75306 },
              { lat: 52.440076, lng: 16.753089 },
              { lat: 52.440464, lng: 16.753234 },
              { lat: 52.440558, lng: 16.753253 },
              { lat: 52.440625, lng: 16.753245 },
              { lat: 52.440833, lng: 16.753132 },
              { lat: 52.44087, lng: 16.752859 },
              { lat: 52.440967, lng: 16.752162 },
              { lat: 52.44105, lng: 16.751467 },
              { lat: 52.441097, lng: 16.750734 }
            ];
            const redCoordinatesPoznan = [
              { lat: 52.437851, lng: 16.761071 },
              { lat: 52.438833, lng: 16.753862 },
              { lat: 52.441157, lng: 16.75518 },
              { lat: 52.441235, lng: 16.755207 },
              { lat: 52.441388, lng: 16.755255 },
              { lat: 52.44146, lng: 16.755223 },
              { lat: 52.441539, lng: 16.755223 },
              { lat: 52.441598, lng: 16.755271 },
              { lat: 52.441637, lng: 16.755389 },
              { lat: 52.441647, lng: 16.755512 },
              { lat: 52.441617, lng: 16.75563 },
              { lat: 52.441552, lng: 16.755706 },
              { lat: 52.441513, lng: 16.755775 },
              { lat: 52.441457, lng: 16.756001 },
              { lat: 52.441346, lng: 16.756306 },
              { lat: 52.441173, lng: 16.756795 },
              { lat: 52.441061, lng: 16.756891 },
              { lat: 52.440868, lng: 16.756977 },
              { lat: 52.440695, lng: 16.756902 },
              { lat: 52.44044, lng: 16.756757 },
              { lat: 52.440312, lng: 16.756698 },
              { lat: 52.440237, lng: 16.756725 },
              { lat: 52.440001, lng: 16.756821 },
              { lat: 52.439452, lng: 16.758243 },
              { lat: 52.438748, lng: 16.760111 }
            ];

            const bluePathWarszawa = new google.maps.Polyline({
              path: blueCoordinatesWarszawa,
              geodesic: true,
              strokeColor: '#237bff',
              strokeOpacity: 1,
              strokeWeight: 5
            });
            const bluePathPoznan = new google.maps.Polyline({
              path: blueCoordinatesPoznan,
              geodesic: true,
              strokeColor: '#237bff',
              strokeOpacity: 1,
              strokeWeight: 5
            });
            const redPathWarszawa = new google.maps.Polyline({
              path: redCoordinatesWarszawa,
              geodesic: true,
              strokeColor: '#e93430',
              strokeOpacity: 1,
              strokeWeight: 5
            });
            const redPathPoznan = new google.maps.Polyline({
              path: redCoordinatesPoznan,
              geodesic: true,
              strokeColor: '#e93430',
              strokeOpacity: 1,
              strokeWeight: 5
            });

            bluePathWarszawa.setMap(mapElement);
            bluePathPoznan.setMap(mapElement);
            redPathWarszawa.setMap(mapElement);
            redPathPoznan.setMap(mapElement);
  }
}
