$(document).on("turbolinks:load", function(){
  if ($('.inch-maps__container').length == 0 || $("#home").length != 0) {
    return false 
  }
  bindMaps();
});

function bindMaps() {
  $(window).on('load', function () {
    var dataSourceLat = ""
    var dataSourceLng = ""
    const mapOptions = {
      zoom: 16,
      //center: myLatlng,
      styles: [
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5'
            }
          ]
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161'
            }
          ]
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#f5f5f5'
            }
          ]
        },
        {
          featureType: 'administrative',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#fffbfc'
            }
          ]
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#bdbdbd'
            }
          ]
        },
        {
          featureType: 'administrative.locality',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#fffbfc'
            }
          ]
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#fffbfc'
            }
          ]
        },
        {
          featureType: 'administrative.province',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#fffbfc'
            }
          ]
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#e4e3df'
            }
          ]
        },
        {
          featureType: 'landscape.natural',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#eeeeee'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575'
            }
          ]
        },
        {
          featureType: 'poi.attraction',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.medical',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e5e5e5'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e'
            }
          ]
        },
        {
          featureType: 'poi.place_of_worship',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.school',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.sports_complex',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dadada'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#a9acac'
            },
            {
              weight: 1.5
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#71797f'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e'
            }
          ]
        },
        {
          featureType: 'transit',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e5e5e5'
            }
          ]
        },
        {
          featureType: 'transit.station',
          elementType: 'geometry',
          stylers: [
            {
              color: '#eeeeee'
            }
          ]
        },
        {
          featureType: 'transit.station.airport',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#c9c9c9'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e'
            }
          ]
        }
      ]
    };
    const blueCoordinatesWarszawa = [
      { lat: 52.235942, lng: 20.956426 },
      { lat: 52.235949, lng: 20.956326 },
      { lat: 52.235951, lng: 20.956283 },
      { lat: 52.235936, lng: 20.956257 },
      { lat: 52.235895, lng: 20.956254 },
      { lat: 52.235385, lng: 20.956214 },
      { lat: 52.234221, lng: 20.956129 },
      { lat: 52.233843, lng: 20.95614 },
      { lat: 52.232949, lng: 20.956317 },
      { lat: 52.232668, lng: 20.956403 },
      { lat: 52.232405, lng: 20.956371 },
      { lat: 52.231308, lng: 20.956693 },
      { lat: 52.230527, lng: 20.956824 },
      { lat: 52.229915, lng: 20.956961 },
      { lat: 52.228029, lng: 20.957401 },
      { lat: 52.227805, lng: 20.957433 },
      { lat: 52.227739, lng: 20.957358 },
      { lat: 52.227713, lng: 20.957197 },
      { lat: 52.227707, lng: 20.957057 },
      { lat: 52.227799, lng: 20.956907 },
      { lat: 52.230147, lng: 20.956379 },
      { lat: 52.23104, lng: 20.956103 },
      { lat: 52.232155, lng: 20.955876 },
      { lat: 52.233213, lng: 20.955715 },
      { lat: 52.233368, lng: 20.955689 },
      { lat: 52.233539, lng: 20.955742 },
      { lat: 52.235169, lng: 20.955687 },
      { lat: 52.235786, lng: 20.955698 },
      { lat: 52.236397, lng: 20.955805 },
      { lat: 52.237356, lng: 20.955859 },
      { lat: 52.237941, lng: 20.955966 },
      { lat: 52.239538, lng: 20.956556 },
      { lat: 52.241723, lng: 20.95737 }
    ];
    const redCoordinatesWarszawa = [
      { lat: 52.228754, lng: 20.967983 },
      { lat: 52.22861, lng: 20.966332 },
      { lat: 52.228406, lng: 20.963334 },
      { lat: 52.228298, lng: 20.961645 },
      { lat: 52.228243, lng: 20.960083 },
      { lat: 52.228219, lng: 20.95845 },
      { lat: 52.22816, lng: 20.957522 },
      { lat: 52.228169, lng: 20.957297 },
      { lat: 52.228173, lng: 20.957249 },
      { lat: 52.228242, lng: 20.957163 },
      { lat: 52.22908, lng: 20.956981 },
      { lat: 52.23069, lng: 20.956615 },
      { lat: 52.23187, lng: 20.95632 },
      { lat: 52.232945, lng: 20.956036 },
      { lat: 52.233921, lng: 20.955955 },
      { lat: 52.235419, lng: 20.955977 },
      { lat: 52.235718, lng: 20.955998 },
      { lat: 52.236559, lng: 20.956089 },
      { lat: 52.236608, lng: 20.956095 },
      { lat: 52.236661, lng: 20.956148 },
      { lat: 52.236664, lng: 20.956197 },
      { lat: 52.236661, lng: 20.956261 },
      { lat: 52.236654, lng: 20.956315 },
      { lat: 52.236611, lng: 20.95632 },
      { lat: 52.23601, lng: 20.956272 },
      { lat: 52.235981, lng: 20.956288 },
      { lat: 52.235961, lng: 20.956433 }
    ];
    const blueCoordinatesWroclaw = [
      { lat: 51.061153, lng: 16.994706 },
      { lat: 51.061466, lng: 16.993887 },
      { lat: 51.061478, lng: 16.993861 },
      { lat: 51.0615, lng: 16.993847 },
      { lat: 51.061527, lng: 16.993871 },
      { lat: 51.06216, lng: 16.994477 },
      { lat: 51.062182, lng: 16.994509 },
      { lat: 51.062185, lng: 16.994555 },
      { lat: 51.061636, lng: 16.996012 },
      { lat: 51.061462, lng: 16.996492 },
      { lat: 51.061334, lng: 16.996841 },
      { lat: 51.061097, lng: 16.997823 },
      { lat: 51.060824, lng: 16.998939 },
      { lat: 51.060514, lng: 17.000146 },
      { lat: 51.060264, lng: 17.001192 },
      { lat: 51.060093, lng: 17.002059 },
      { lat: 51.059978, lng: 17.002891 },
      { lat: 51.059813, lng: 17.004238 },
      { lat: 51.059739, lng: 17.00498 },
      { lat: 51.059655, lng: 17.005697 },
      { lat: 51.059578, lng: 17.006389 },
      { lat: 51.059575, lng: 17.006502 },
      { lat: 51.059544, lng: 17.00684 },
      { lat: 51.059571, lng: 17.006947 },
      { lat: 51.059625, lng: 17.007113 },
      { lat: 51.059612, lng: 17.007221 },
      { lat: 51.059544, lng: 17.00735 },
      { lat: 51.059494, lng: 17.00736 },
      { lat: 51.059436, lng: 17.007355 },
      { lat: 51.059372, lng: 17.007285 },
      { lat: 51.059328, lng: 17.007205 },
      { lat: 51.059318, lng: 17.007124 },
      { lat: 51.059325, lng: 17.007006 },
      { lat: 51.059342, lng: 17.006958 },
      { lat: 51.059369, lng: 17.006904 },
      { lat: 51.059413, lng: 17.006883 },
      { lat: 51.059463, lng: 17.006861 },
      { lat: 51.05948, lng: 17.006717 },
      { lat: 51.05951, lng: 17.006556 },
      { lat: 51.059537, lng: 17.006373 },
      { lat: 51.059578, lng: 17.006191 },
      { lat: 51.059605, lng: 17.006084 },
      { lat: 51.05961, lng: 17.00607 },
      { lat: 51.059919, lng: 17.003454 },
      { lat: 51.060086, lng: 17.002054 },
      { lat: 51.060583, lng: 16.999914 },
      { lat: 51.061244, lng: 16.997195 },
      { lat: 51.061318, lng: 16.996841 },
      { lat: 51.061261, lng: 16.996611 },
      { lat: 51.060988, lng: 16.995753 },
      { lat: 51.060809, lng: 16.995097 },
      { lat: 51.060583, lng: 16.994211 },
      { lat: 51.060215, lng: 16.992691 },
      { lat: 51.059836, lng: 16.991169 },
      { lat: 51.059402, lng: 16.989394 },
      { lat: 51.059129, lng: 16.988606 },
      { lat: 51.058799, lng: 16.987898 }
    ];
    const redCoordinatesWroclaw = [
      { lat: 51.061205, lng: 16.995293 },
      { lat: 51.061093, lng: 16.994869 },
      { lat: 51.061137, lng: 16.994751 },
      { lat: 51.061147, lng: 16.994703 },
      { lat: 51.061107, lng: 16.994644 },
      { lat: 51.061016, lng: 16.994483 },
      { lat: 51.060982, lng: 16.994467 },
      { lat: 51.060948, lng: 16.994574 },
      { lat: 51.060908, lng: 16.994885 },
      { lat: 51.060938, lng: 16.995185 },
      { lat: 51.061043, lng: 16.995502 },
      { lat: 51.061423, lng: 16.996478 },
      { lat: 51.061841, lng: 16.99753 },
      { lat: 51.062283, lng: 16.998527 },
      { lat: 51.062917, lng: 16.999976 },
      { lat: 51.063285, lng: 17.000706 },
      { lat: 51.063721, lng: 17.001424 },
      { lat: 51.063991, lng: 17.001744 },
      { lat: 51.064318, lng: 17.00198 },
      { lat: 51.064847, lng: 17.002296 },
      { lat: 51.06601, lng: 17.002946 }
    ];
    const blueCoordinatesPoznan = [
      { lat: 52.43779, lng: 16.761023 },
      { lat: 52.438738, lng: 16.753621 },
      { lat: 52.439424, lng: 16.754033 },
      { lat: 52.439467, lng: 16.753698 },
      { lat: 52.439559, lng: 16.753428 },
      { lat: 52.43967, lng: 16.753222 },
      { lat: 52.439701, lng: 16.753179 },
      { lat: 52.43985, lng: 16.753065 },
      { lat: 52.439909, lng: 16.75306 },
      { lat: 52.440076, lng: 16.753089 },
      { lat: 52.440464, lng: 16.753234 },
      { lat: 52.440558, lng: 16.753253 },
      { lat: 52.440625, lng: 16.753245 },
      { lat: 52.440833, lng: 16.753132 },
      { lat: 52.44087, lng: 16.752859 },
      { lat: 52.440967, lng: 16.752162 },
      { lat: 52.44105, lng: 16.751467 },
      { lat: 52.441097, lng: 16.750734 }
    ];
    const redCoordinatesPoznan = [
      { lat: 52.437851, lng: 16.761071 },
      { lat: 52.438833, lng: 16.753862 },
      { lat: 52.441157, lng: 16.75518 },
      { lat: 52.441235, lng: 16.755207 },
      { lat: 52.441388, lng: 16.755255 },
      { lat: 52.44146, lng: 16.755223 },
      { lat: 52.441539, lng: 16.755223 },
      { lat: 52.441598, lng: 16.755271 },
      { lat: 52.441637, lng: 16.755389 },
      { lat: 52.441647, lng: 16.755512 },
      { lat: 52.441617, lng: 16.75563 },
      { lat: 52.441552, lng: 16.755706 },
      { lat: 52.441513, lng: 16.755775 },
      { lat: 52.441457, lng: 16.756001 },
      { lat: 52.441346, lng: 16.756306 },
      { lat: 52.441173, lng: 16.756795 },
      { lat: 52.441061, lng: 16.756891 },
      { lat: 52.440868, lng: 16.756977 },
      { lat: 52.440695, lng: 16.756902 },
      { lat: 52.44044, lng: 16.756757 },
      { lat: 52.440312, lng: 16.756698 },
      { lat: 52.440237, lng: 16.756725 },
      { lat: 52.440001, lng: 16.756821 },
      { lat: 52.439452, lng: 16.758243 },
      { lat: 52.438748, lng: 16.760111 }
    ];
    const bluePathWarszawa = new google.maps.Polyline({
      path: blueCoordinatesWarszawa,
      geodesic: true,
      strokeColor: '#237bff',
      strokeOpacity: 1,
      strokeWeight: 5
    });
    const bluePathWroclaw = new google.maps.Polyline({
      path: blueCoordinatesWroclaw,
      geodesic: true,
      strokeColor: '#237bff',
      strokeOpacity: 1,
      strokeWeight: 5
    });
    const bluePathPoznan = new google.maps.Polyline({
      path: blueCoordinatesPoznan,
      geodesic: true,
      strokeColor: '#237bff',
      strokeOpacity: 1,
      strokeWeight: 5
    });
    const redPathWarszawa = new google.maps.Polyline({
      path: redCoordinatesWarszawa,
      geodesic: true,
      strokeColor: '#e93430',
      strokeOpacity: 1,
      strokeWeight: 5
    });
    const redPathWroclaw = new google.maps.Polyline({
      path: redCoordinatesWroclaw,
      geodesic: true,
      strokeColor: '#e93430',
      strokeOpacity: 1,
      strokeWeight: 5
    });
    const redPathPoznan = new google.maps.Polyline({
      path: redCoordinatesPoznan,
      geodesic: true,
      strokeColor: '#e93430',
      strokeOpacity: 1,
      strokeWeight: 5
    });
  //bluePathWarszawa.setMap(mapElement);
  //bluePathWroclaw.setMap(mapElement);
  //bluePathPoznan.setMap(mapElement);
  //redPathWarszawa.setMap(mapElement);
  //redPathWroclaw.setMap(mapElement);
  //redPathPoznan.setMap(mapElement);
    function initMap(element_id, latPosition, lngPosition) {
      const myLatlng = new google.maps.LatLng(latPosition, lngPosition);
      mapOptions["center"] = myLatlng
      const mapElement = new google.maps.Map(document.getElementById(element_id), mapOptions);
      const marker = new google.maps.Marker({
        map: mapElement,
        position: myLatlng,
      });
    }

    if ($('#inch-maps__container-poznan').length) {
      initMap("inch-maps__container-poznan", "52.438166", "16.7570053")
    }
    if ($('#inch-maps__container-warszawa').length) {
      initMap("inch-maps__container-warszawa", "52.1954381","20.9414662")
    }
    if ($('#inch-maps__container-wroclaw').length) {
      initMap("inch-maps__container-wroclaw", "51.06152436386761", "16.995127251368018")
    }
  });
}
